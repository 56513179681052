<template>
  <v-select
    v-show="hasUnidades"
    clearable
    dense
    item-text="unidade"
    item-value="id"
    :items="unidades"
    placeholder="nenhuma selecionada"
    prefix="Unidade:"
    prepend-icon="mdi-clipboard-edit-outline"
    :value="value"
    @change="$emit('change', $event)"
    @input="$emit('input', $event)"
  ></v-select>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['clientId']),
    hasUnidades: function () {
      return Array.isArray(this.unidades) && this.unidades.length > 0;
    }
  },
  created: function () {
    this.getUnidades();
  },
  data: function () {
    return {
      unidades: [],
    };
  },
  methods: {
    getUnidades: function () {
      this.unidades = [];
      return this.apiResource(`/v1/clientes/${this.clientId}/unidades`).get().then((res) => {
        this.unidades = res;
        this.$emit('unidades', res);
      })
    }
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: Number,
  },
  watch: {
    clientId: function () {
      this.getUnidades();
    },
  },
};
</script>

<style lang="scss" scoped>
.v-input.v-select::v-deep .v-input__control > .v-input__slot > .v-select__slot > .v-select__selections > input::placeholder {
  color: unset !important;
  opacity: 1; /* Firefox */
}
</style>
